@media (min-width: 768px) {
body {
	margin-top: 0;
}
.navbar-nav.main-nav .dropdown-item .nav-link {
	color: var(--primary);
}
.navbar-nav.main-nav .dropdown-item:hover .nav-link {
	color: var(--white);
}
.banner-caption .quote-form {
	position: absolute;
	left:0;
	top:-3rem;
	width: 100%;
	z-index: 10; 
}
}
@media (min-width: 992px) {
.sticky .navbar-brand {
	max-width: 130px;
}
.sticky .navbar-nav.main-nav .nav-item {
	padding: 1rem 1rem;
}
.navbar-nav.main-nav .dropdown:hover > .dropdown-menu {
	display: block;
}
.dropdown-menu .dropdown-menu {
	top: 0;
	left: 100%
}
.dropdown-menu {
	border: 0;
	border-radius: 0;
	margin: 0;
	padding: 0;
	box-shadow: 0 0 5px rgba(0,0,0,0.25);
}
.footer-widget-links li {
	width: 50%;
	margin-right: -4px !important;
}
}
@media (max-width: 992px) {
.navigation-wrapper {
	width: 100%;
}
.navbar.navbar-expand-lg {
	width: 100%;
}
.navbar-nav.main-nav .nav-link {
	font-size: 1rem;
 padding: .75rem .65rem;
}
.navbar.navbar-expand-lg #navbarNav {
	background-color: #fff;
}
#navbarNav .btn {
    width: 50%;
    margin-right: -4px;
}
}
@media (min-width:1200px) {
}
@media only screen and (min-width: 768px) and (max-width:1024px) {
.carousel-caption {
	position: absolute;
	right: 2%;
	bottom: 0;
	left: 2%;
	padding-top: 5px;
	padding-bottom: 5px;
}
.title-text {
	font-size: 1.8rem;
}
.banner-title {
	font-size: 2rem;
}
.banner-sub-title {
	font-size: 2rem;
}
.banner-para {
	font-size: 1.25rem;
}
.fancy-text {
	font-size: 2.5rem;
}
.btn-lg, .btn-group-lg > .btn {
	padding: 1rem 1.85rem;
	font-size: 1rem;
}
}
@media (min-width: 768px)and (max-width: 992px) {
.lead {
	font-size: 16px;
}
h1, .h1 {
	font-size: 28px;
}
h2, .h2 {
	font-size: 24px;
}
h3, .h3 {
	font-size: 20px;
}
h4, .h4 {
	font-size: 18px;
}
h5, .h5 {
	font-size: 16px;
}
h6, .h6 {
	font-size: 14px;
}
.header .navbar-brand {
	max-width: 150px;
}
.banner-wrapper {
    min-height: 50vh;
}
.banner-title {
	font-size: 1.5rem;
	font-weight: 800;
}
.banner-sub-title {
	font-size: 1.5rem;
	font-weight: 800;
}
.banner-para {
	font-size: 1rem;
}
}
@media only screen and (min-width: 1025px) and (max-width:1199px) {
.header .navbar-brand {
	max-width: 220px;
}
}
@media only screen and (min-width: 1025px) and (max-width:1360px) {
}
@media only screen and (min-width:1200px) and (max-width:1280px) {
}
@media only screen and (min-width:1300px) and (max-width:1366px) {
}
@media (min-width: 992px)and (max-width: 1024px) {
}
@media (max-width: 767px) {
html {
	font-size: 12px;
}
body {
	padding-top: 65px;
}
h1, .h1 {
	font-size: 24px;
}
h2, .h2 {
	font-size: 20px;
}
h3, .h3 {
	font-size: 18px;
}
h4, .h4 {
	font-size: 16px;
}
h5, .h5 {
	font-size: 14px;
}
h6, .h6 {
	font-size: 12px;
}
.header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	background-color: #fff;
	z-index: 100;
	height: 65px;
}
.header .navbar-brand {
	max-width: 120px;
	padding-left: 15px;
}
.title {
	margin-bottom: 10px;
	margin-top: 5px;
}
body, .lead {
	font-size: 14px;
}
.navbar-nav.main-nav .nav-item {
	padding: 1rem 1rem;
}
.navbar-nav.main-nav .nav-link {
	font-size: 1.15rem;
 padding: 0rem .65rem;
}
.navbar-nav.main-nav .dropdown-menu {
	background-color: rgba(0, 0, 0, 0.15);
}
.navbar-nav.main-nav .dropdown-item:hover, .navbar-nav.main-nav .dropdown-item:focus {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.15);
}
.navbar-nav.main-nav .dropdown-menu .dropdown-item .dropdown-menu .nav-link {
	padding-left: 5%;
}
.banner-title {
	font-size: 1.5rem;
	font-weight: 600;
}
.banner-sub-title {
    font-size: 1rem;
    font-weight: 600;
}
.btn-lg, .btn-group-lg > .btn {
 padding: .5rem 1.5rem;
	font-size: 1.2rem;
}
.author-image,.testimonials-author{
	text-align: center;
	margin:0 0 1rem;
}
.testimonials-media .media-body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	align-items: center;
	text-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}
}
@media (max-width:375px) {
}
@media (min-width:590px) {
}
