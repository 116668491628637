/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
	-webkit-tap-highlight-color: transparent;
	position: relative
}
.owl-carousel {
	display: none;
	width: 100%;
	z-index: 1
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	touch-action: manipulation;
	-moz-backface-visibility: hidden
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0)
}
.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0)
}
.owl-carousel .owl-item {
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-touch-callout: none
}
.owl-carousel .owl-item img {
	display: inline-block;
	max-width: 100%
}
.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
	display: none
}
.no-js .owl-carousel, .owl-carousel.owl-loaded {
	display: block
}
.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
	background: 0 0;
	color: inherit;
	border: none;
	padding:.5rem 1rem;
	font: .875rem
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block
}
.owl-carousel.owl-hidden {
	opacity: 0
}
.owl-carousel.owl-refresh .owl-item {
	visibility: hidden
}
.owl-carousel.owl-drag .owl-item {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab
}
.owl-carousel.owl-rtl {
	direction: rtl
}
.owl-carousel.owl-rtl .owl-item {
	float: right
}
.owl-carousel .animated {
	animation-duration: 1s;
	animation-fill-mode: both
}
.owl-carousel .owl-animated-in {
	z-index: 0
}
.owl-carousel .owl-animated-out {
	z-index: 1
}
.owl-carousel .fadeOut {
	animation-name: fadeOut
}
@keyframes fadeOut {
0% {
opacity:1
}
100% {
opacity:0
}
}
.owl-height {
	transition: height .5s ease-in-out
}
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity .4s ease
}
.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
	max-height: 0
}
.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d
}
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	/*background: url(owl.video.play.png) no-repeat;*/
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform .1s ease
}
.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3)
}
.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
	display: none
}
.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity .4s ease
}
.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%
}
.owl-theme .owl-dots, .owl-theme .owl-nav {
	text-align: center;
	-webkit-tap-highlight-color: transparent
}
.owl-theme .owl-nav {
	margin-top: 10px
}
.owl-theme .owl-nav [class*=owl-] {
	color: #fff;
	font-size: .875rem;
	margin: 5px;
	padding: 4px 7px;
	background: #787776;
	display: inline-block;
	cursor: pointer;
	border-radius: 3px
}
.owl-theme .owl-nav [class*=owl-]:hover {
	background: #787776;
	color: #fff;
	text-decoration: none
}
.owl-theme .owl-nav .disabled {
	opacity: .5;
	cursor: default
}
.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 10px
}
.owl-theme .owl-dots .owl-dot {
	display: inline-block;
	padding:.15rem;
	zoom: 1
}
.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 0;
	background: #d8d6d4;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	background: var(--primary)
}
@media (min-width:768px){
.concept-carousel .owl-nav{
    display: none !important;
    position: absolute;
    width: 100%;
    top: 20%;
    transform: translateY(-50%);
}
.concept-carousel:hover .owl-nav {
    display: block !important;
}
.concept-carousel .owl-nav [class*="owl-"]:hover{
    background-color:transparent;
	color: var(--primary);
}
.concept-carousel .owl-nav button,.testimonials-carousel .owl-nav button {
    position: absolute;
    top: 50%;
    padding: 2px 0px !important;
    display: inline-block;
	background-color:transparent;
    transition: all .3s ease-in-out !important;
    -webkit-transition: all .3s ease-in-out !important;
    -moz-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;

}
.concept-carousel .owl-nav button i,.testimonials-carousel .owl-nav button i{
	font-size:2.5rem;
}
.concept-carousel .owl-nav .owl-prev ,.testimonials-carousel .owl-nav .owl-prev {
    left: 10px;
}
.concept-carousel .owl-nav .owl-next,.testimonials-carousel .owl-nav .owl-next {
    right: 10px;
}
}
