.header {
	box-shadow: 0 2px 6px rgba(0,0,0,0.2);
	z-index: 1030;
}
.header, .header * {
	transition: all 0.25s ease-in-out 0s;
	-webkit-transition: all 0.25s ease-in-out 0s;
	-moz-transition: all 0.25s ease-in-out 0s;
	-ms-transition: all 0.25s ease-in-out 0s;
	-o-transition: all 0.25s ease-in-out 0s;
}
@keyframes slide-down {
 0% {
 opacity: 0;
 transform: translateY(-100%);
}
 100% {
 opacity: 0.9;
 transform: translateY(0);
}
}
.sticky {
	box-shadow: 0 2px 6px rgba(0,0,0,0.2);
	animation: slide-down 0.7s;
	background-color: var(--white);
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.navbar-brand, .footer-widget-logo {
	max-width: 160px;
}
.navbar-nav.main-nav .nav-item {
	padding: 1.25rem 1rem;
}
.navbar-nav.main-nav .nav-item > .nav-link {
	color: var(--dark);
 font-size: .925rem;
 padding:0 0 .5rem;
	font-weight: 600
}
.navbar-nav.main-nav .nav-item > .nav-link:hover, .navbar-nav.main-nav .nav-item.active > .nav-link {
	color: var(--primary);
	box-shadow: 0 -3px 0 var(--primary) inset;
}
.navbar-nav.main-nav .dropdown-item {
	font-weight: 600;
	text-transform: capitalize;
}
#navbarNav .btn {
	padding: 2rem 1.5rem;
	border-radius: 0
}
.banner-wrapper, .pageheader-wrapper, .apps-section {
	background: url(../images/background/banner.jpg) no-repeat 0 0;
	background-size: cover;
	min-height: 70vh;
}
.pageheader-wrapper {
	min-height: 150px;
}
.comingsoon-wrapper {
	background: url(../images/background/banner.jpg) no-repeat 0 0;
	background-size: cover;
	min-height: 100vh;
}
.banner-title {
	font-weight: 700;
	font-size: 2.5rem;
}
.banner-sub-title {
	font-weight: 700;
}
.banner-para {
	font-weight: 500;
	font-size: 1.25rem;
}
.fancy-form-group {
	position: relative;
	margin-top: 1.5rem;
	margin-bottom: 1.1rem;
}
.row .fancy-form-group {
	margin-top: 0;
}
.fancy-form-group .form-control {
	border: 0;
	border-bottom: 1px solid var(--light);
	border-radius: 0;
	padding-left: 0
}
.fancy-form-group .form-control:focus, .fancy-form-group.filled .form-control {
	border-color: var(--primary);
	box-shadow: none;
}
.fancy-form-group label {
	position: absolute;
	/*top: 8px;*/
	top: 0px;
	left: 0;
	transform-origin: 0 0;
	cursor: text;
	color: #757575;
	text-transform: uppercase;
	font-weight: 600;
	margin: 0;
 font-size:.75rem;
	/*z-index: 1;*/
	transition: all 0.25s ease-in-out 0s;
	-webkit-transition: all 0.25s ease-in-out 0s;
	-moz-transition: all 0.25s ease-in-out 0s;
	-ms-transition: all 0.25s ease-in-out 0s;
	-o-transition: all 0.25s ease-in-out 0s;
}
.fancy-form-group.focus label, .fancy-form-group.filled label {
	color: var(--gray);
	top: -15px;
 font-size:.65rem;
}
.title-text {
	font-size: 2.375rem;
}
.title-text small {
	font-size: 1.5rem;
	font-weight: 600;
	font-family: var(--font-family-secondary);
	font-style: italic;
	color: var(--success);
	padding-left: 3rem;
	position: relative;
}
.title-text small::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	height: 5px;
	width: 2.5rem;
	background-color: var(--success);
	border-radius: 5rem;
}
.process-card {
	box-shadow: 0 0 32px rgba(0,0,0,0.1);
	position: relative
}
.process-number {
	position: absolute;
	color: var(--light);
	font-weight: 900;
	font-size: 8rem;
	right: 0px;
	top: -3.5rem;
}
.brand-logo {
	background-color: #fff;
	height: 280px;
	width: 280px;
	padding: 2rem;
	border-radius: 50%;
	margin-bottom: 2rem;
}
.section-cta {
	background: url(../images/background/pattern.png) repeat 0px 0px;
}
.testimonials-section {
	background: url(../images/background/map.png) no-repeat center center;
}
.testimonials-author-text {
	font-family: "Bad Script", Arial, Helvetica, sans-serif;
	font-size: 1.5rem;
}
.apps-section {
	min-height:100%;
}
.features-icon {
    vertical-align: top;
     line-height: 0; 
}
.footer-widget, .copyright {
 font-size:.875rem;
}
.newsletter-section .form-control {
	background-color: rgba(255,255,255,.1);
	border: 0;
 font-size:.875rem;
	color: var(--white);
	height: calc(2rem + 0.75rem);
}
.newsletter-section .form-control::-moz-placeholder {
 color:var(--gray);
 opacity: 1;
}
.newsletter-section .form-control:-ms-input-placeholder {
 color:var(--gray);
 opacity: 1;
}
.newsletter-section .form-control::-ms-input-placeholder {
 color: var(--gray);
 opacity: 1;
}
.newsletter-section .form-control::placeholder {
 color: var(--gray);
 opacity: 1;
}
.footer hr {
	border-color: rgba(255,255,255,.1)
}
.footer-widget a, .copyright a {
	color: var(--gray);
	text-decoration: none;
}
.footer-widget a:hover, .copyright a:hover {
	color: var(--secondary);
}
.footer-widget-menu li a {
 font-size:.875rem;
 padding-bottom:.5rem;
	display: inline-block;
}
.mail-caption {
	display:block;
	font-weight: 600;
	color: var(--white);
	 font-size:.75rem;
	text-transform: uppercase
}
.footer-widget-menu {
	margin: 0;
	padding: 0;
}
.social-links li a {
 margin-right: .65rem;
	font-size: 1.25rem;
}
.social-links .nav-link {
	padding: 0;
}
.forgot-password {
	position: relative;
}
.forgot-password a {
	position: absolute;
	right: 0;
	top: -3rem;
	z-index: 10;
	opacity: .5;
	font-weight: 500;
	font-style: italic;
}
.forgot-password a:hover {
	opacity: 1;
}
.register-form .nav-tabs .nav-item {
	margin: 0 0 0 1rem;
}
.register-form .nav-tabs .nav-link {
 font-size:.875rem;
	font-weight: 500;
	border: 0;
	padding: 0;
	text-transform: uppercase;
	font-weight: 600;
	color: var(--gray);
	border-bottom: 2px solid transparent;
}
.register-form .nav-tabs .nav-link.active {
	color: var(--primary);
	border-color: var(--primary)
}
.sidebar-menu .list-group-item {
	transition: all 0.25s ease-in-out 0s;
	-webkit-transition: all 0.25s ease-in-out 0s;
	-moz-transition: all 0.25s ease-in-out 0s;
	-ms-transition: all 0.25s ease-in-out 0s;
	-o-transition: all 0.25s ease-in-out 0s;
}
.sidebar-menu .list-group-item a {
	text-decoration: none;
	/* display: block; */
	color: var(--dark);
	display: flex;
	align-items: baseline;
}
.sidebar-menu .list-group-item:hover {
	background-color: var(--white)
}
.sidebar-menu .list-group-item:hover a{
	color: var(--primary);
}
.sidebar-menu .list-group-item.active a {
	color: var(--white);
}
.sidebar-menu .list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: var(--primary);
	border-color: var(--primary);
}
.service-company-logo img {
	width: 80px;
	filter: grayscale
}
.payment-card-logo img {
	width: 50px;
	filter: grayscale
}
.service-company {
 font-size:.75rem;
	font-style: italic;
	font-weight: 400;
	color: var(--primary);
}
.orders-shiping-address {
	border: 1px dashed var(--gray-light);
}
.orders-status {
 font-size:.5rem;
	font-style: italic;
	font-weight: 600;
	color: var(--white);
 padding:.35rem .45rem;
}
.shipping-status {
   font-size:.75rem;
	font-style: italic;
	font-weight: 600;
	color: var(--primary);
}
.shiping-icon > i {
	font-size: 1.5rem;
	opacity: .5
}
.order-detail-btn {
 font-size:.75rem;
	font-weight: 600;
	color: var(--primary);
}
.orders-media-icon {
	font-size: 2rem;
	margin-right: 1.5rem;
}
.list-group-item p{
	font-size:.85rem;
	font-weight:400;
	margin-bottom:.25rem;
	color:var(--gray);
}
.text-cap{
	color:var(--gray-dark);
	font-weight:600;
	font-size:.75rem;
}
.company-listing{
	padding:1rem;
	border:1px dashed var(--gray-light);
	border-radius:.25rem;
	margin-bottom:.5rem;
	transition: all 0.25s ease-in-out 0s;
	-webkit-transition: all 0.25s ease-in-out 0s;
	-moz-transition: all 0.25s ease-in-out 0s;
	-ms-transition: all 0.25s ease-in-out 0s;
	-o-transition: all 0.25s ease-in-out 0s;
}
.service-price{
	color:var(--gray);
}
.company-listing:hover,.company-listing.active{
	border:1px solid var(--primary);
}
.company-listing.active .service-price{
	color:var(--dark);
}
.company-listing .custom-control {
    min-height: 2.5rem;
    padding-left: 2.5rem;
}
.company-listing .custom-control-label:before ,.company-listing .custom-control-label:after {
    width: 2rem;
    height: 2rem;
}
.contact-widget .mail-caption{
	color:var(--dark)
}
.MuiFormControl-marginNormal{
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.MuiInput-underline::before{
	border-color: #ddd !important;
}

.MuiInput-underline::after{
	border-color: #056bcf !important;
}
.MuiInput-underline.Mui-error::after{
	border-color: #f44336 !important;
}
.MuiFormHelperText-root{
	display: none;
}
.MuiFormLabel-root{
	font-family: inherit !important;
}


.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__input {
	width: 240px;
	height: 30px;
	padding: 10px 20px;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	border: 1px solid #aaa;
	border-radius: 4px;
}

.react-autosuggest__input:focus {
	outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
	display: block;
	position: absolute;
	top: 51px;
	width: 280px;
	border: 1px solid #aaa;
	background-color: #fff;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
	border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
	background-color: #0C7EAF;
	color: #fff;
}

.suggestion-content {
	display: flex;
	align-items: center;
	background-repeat: no-repeat;
}

.dancounsell {
	background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/dancounsell/48.jpg);
}

.ladylexy {
	background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/ladylexy/48.jpg);
}

.mtnmissy {
	background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/mtnmissy/48.jpg);
}

.steveodom {
	background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/steveodom/48.jpg);
}

.name {
	margin-left: 68px;
	line-height: 45px;
}

.highlight {
	color: #ee0000;
	font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
	color: #120000;
}

.loader{
	height: 100%;
	width: 100%;
	text-align: center;
	top: 0;
	left: 0;
	z-index: 9999;
	background: rgba(255,255,255,0.6);
	display: flex;
	align-items: center;
	justify-content: center;
}

.inline-loading{
	padding: 10%;
}

.box-loading{
	position: fixed;
}

.card-image {
	position: absolute;
	right: 0;
	bottom: 10px;
	max-width: 2rem;
  }
  .card-image img {
	max-width: 100%;
  }

.card-company-logo img {
	width: 50px;
}
.quote_title {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
}
.my-orders .card-body .alert-dismissible .close{
	display: none;
}

.down-opacity{
	position:absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.6);
	z-index: 1;
}
.strike-line{
	text-decoration: line-through;
}